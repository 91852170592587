import React, { forwardRef, useImperativeHandle } from "react";
import { navigate } from "gatsby";
import classNames from "classnames";
import { Link } from "../link";
import Icon from "./icon-svg";

const SeeMore = forwardRef(({ link, color, className, ...others }, ref) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        click() {
          if (link) {
            navigate(link);
          }
        },
      };
    },
    [link]
  );
  return link ? (
    <Link to={link} className={classNames("see-more", color, className)} {...others}>
      <Icon name="long-arrow-right-solid" />
    </Link>
  ) : (
    <div className={classNames("see-more", color, className)} {...others}>
      <Icon name="long-arrow-right-solid" />
    </div>
  );
});

export default SeeMore;
