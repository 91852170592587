import React from "react";
import { graphql } from "gatsby";
import SlideHeaderContent from "../slide-header-content";
import withPreview from "../../utility/with-preview";

const SlideHeaderBis = ({ data }) => {
  return <SlideHeaderContent {...data} variant="bis" />;
};

export default withPreview(SlideHeaderBis);
export const slideHeaderBisFragment = graphql`
  fragment SlideHeaderBisFragment on LiferaySlideHeaderBis {
    liferayFields {
      articleId
      siteId
    }
    slide {
      value {
        description
      }
      node {
        gatsbyImageData
      }
    }
    titolo {
      value
    }
    descrizione {
      value
    }
    modello {
      value
    }
    #breadcrumb {
    #  value
    #}
  }
`;
