import React from "react";
import { graphql } from "gatsby";
import EditorialeGenerico from "../fasce/editoriale-generico";

const NewsEditorialContent = ({ editorialContent, ...other }) => {
  switch (editorialContent.__typename) {
    case "LiferayEditorialeGenerico":
      return <EditorialeGenerico data={editorialContent} {...other} />;
    default:
      console.warn(
        `Cannot handle ${editorialContent.__typename} type for editorial content in pagina news`
      );
      return null;
  }
};

export default NewsEditorialContent;
export const query = graphql`
  fragment NewsEditorialContentFragment on LiferayWebContent {
    __typename
    ...EditorialeGenericoFragment
  }
`;
