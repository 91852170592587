import React from "react";
import { graphql } from "gatsby";
import SlideHeader from "../fasce/hero-slideheader";
import SlideHeaderBis from "../fasce/slide-header-bis";

const NewsHeader = ({ header, ...other }) => {
  switch (header.__typename) {
    case "LiferaySlideHeader":
      return <SlideHeader data={header} {...other} />;
    case "LiferaySlideHeaderBis":
      return <SlideHeaderBis data={header} {...other} />;
    default:
      console.warn(`Cannot handle ${header.__typename} type for header in pagina news`);
      return null;
  }
};

export default NewsHeader;
export const query = graphql`
  fragment NewsHeaderFragment on LiferayWebContent {
    __typename
    ...SlideHeaderFragment
    ...SlideHeaderBisFragment
  }
`;
