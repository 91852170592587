import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SocialShare from "../elements/social-share";
import withPreview from "../../utility/with-preview";

const EditorialeGenerico = ({ data, location, title }) => {
  const { articolo, coloreTesto, nascondisocial } = data;
  return (
    <section className="section editoriale-generico">
      {articolo.map(
        (itemArticolo, i) =>
          (itemArticolo?.content?.titolo?.value ||
            itemArticolo?.content?.corpo?.value ||
            itemArticolo?.content?.sezioneimmagine?.value) && (
            <article id="article" className="wrapper-container" key={i}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {(itemArticolo?.content?.titolo?.value ||
                      itemArticolo.content?.corpo?.value) && (
                      <div className="editoriale-generico__bar"></div>
                    )}
                    {itemArticolo?.content?.titolo?.value && (
                      <h2
                        className="editoriale-generico__article-title"
                        style={{ color: coloreTesto?.value }}
                      >
                        {itemArticolo.content.titolo.value}
                      </h2>
                    )}
                    <div
                      className="editoriale-generico__body"
                      dangerouslySetInnerHTML={{ __html: itemArticolo?.content?.corpo?.value }}
                    ></div>
                    {itemArticolo?.content?.sezioneimmagine?.content?.immagine?.node && (
                      <div className="editoriale-generico__image">
                        <GatsbyImage
                          image={getImage(
                            itemArticolo.content.sezioneimmagine.content.immagine.node
                          )}
                          alt=""
                        />
                        {itemArticolo?.sezioneimmagine?.content?.didascaliaimmagine?.value && (
                          <div className="editoriale-generico__caption">
                            {itemArticolo.sezioneimmagine.didascaliaimmagine.value}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </article>
          )
      )}
      {!nascondisocial.value && <SocialShare location={location} title={title} />}
    </section>
  );
};

export default withPreview(EditorialeGenerico);
export const fragment = graphql`
  fragment EditorialeGenericoFragment on LiferayEditorialeGenerico {
    liferayFields {
      siteId
      articleId
    }
    articolo {
      value
      content {
        titolo {
          value
        }
        corpo {
          value
        }
        sezioneimmagine {
          value
          content {
            immagine {
              node {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            didascaliaimmagine {
              value
            }
          }
        }
        sezionevideo {
          value
          content {
            urlvideo {
              value
            }
            didascaliavideo {
              value
            }
          }
        }
        sezionegalleria {
          value
          content {
            immaginegalleria {
              node {
                id
                publicURL
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
    coloreTesto {
      value
    }
    nascondisocial {
      value
    }
  }
`;
