import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageWrapper = ({ image, imgStyle, alt, ...other }) => {
  return image.gatsbyImageData ? (
    <GatsbyImage image={getImage(image)} imgStyle={imgStyle} alt={alt} {...other} />
  ) : (
    <img src={image.publicURL} loading="lazy" alt={alt} {...other} />
  );
};

export default ImageWrapper;
