import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import Layout from "../components/layout";
import Seo from "../components/seo";
import NewsHeader from "../components/news/news-header";
import NewsEditorialContent from "../components/news/news-editorial-content";
import NewsEditorialAttachment from "../components/news/news-editorial-attachment";
import NewsEditorialRelated from "../components/news/news-editorial-related";
import withPreview from "../utility/with-preview";

const PaginaNewsTemplate = ({ data, location }) => {
  const {
    title,
    link,
    seo,
    navMenu,
    topMenu,
    header,
    editorialContent,
    attachment,
    editorialRelated,
    fullWidth,
  } = data.page;

  return (
    <Layout location={location} navMenu={navMenu} topMenu={topMenu?.value}>
      <Seo
        title={seo?.content?.htmlTitle?.value || title?.value}
        description={seo?.content?.metaDescription?.value}
        ogImage={seo?.content?.ogImage?.node}
        path={link}
      />
      <div data-template="pagina-news">
        {header?.value && <NewsHeader header={header.value} />}
        <div className="wrapper-container px-0">
          <div className="row mx-0">
            <div
              className={classNames(
                "px-0",
                attachment?.value ? "col-md-9" : fullWidth?.value ? "col-12" : "col-md-9"
              )}
            >
              {editorialContent?.map((content, i) => (
                <NewsEditorialContent
                  editorialContent={content.value}
                  key={i}
                  location={location}
                />
              ))}
            </div>
            {(attachment?.value || !!editorialRelated?.value?.url?.length || !fullWidth?.value) && (
              <div className="col-md-3 px-0">
                {attachment?.value && <NewsEditorialAttachment attachment={attachment.value} />}
                {editorialRelated?.value?.url?.length && (
                  <NewsEditorialRelated editorialRelated={editorialRelated} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withPreview(PaginaNewsTemplate, {
  subField: "page",
  fixed: true,
});
export const query = graphql`
  query ($id: String) {
    page: liferayJskLayoutPaginaNews(id: { eq: $id }) {
      liferayFields {
        siteId
        articleId
      }
      link
      seo {
        content {
          htmlTitle {
            value
          }
          metaDescription {
            value
          }
          ogImage {
            node {
              resize(width: 1200) {
                src
                width
                height
              }
            }
          }
          metaRobots {
            value
          }
        }
      }
      title {
        value
      }
      navMenu {
        ...MenuNavigazioneFragment
      }
      topMenu {
        value {
          ...JskTopMenuFragment
        }
      }
      header {
        value {
          ...NewsHeaderFragment
        }
      }
      editorialContent {
        value {
          ...NewsEditorialContentFragment
        }
      }
      attachment {
        value {
          ...NewsEditorialAttachmentFragment
        }
      }
      editorialRelated {
        value {
          ... on LiferayCorrelatoEditoriale {
            url {
              value
              content {
                titolo {
                  value
                }
                immagine {
                  node {
                    gatsbyImageData(width: 600)
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
      fullWidth {
        value
      }
    }
  }
`;
