import React from "react";
import { graphql } from "gatsby";
import AllegatoEditoriale from "./allegato-editoriale";

const NewsEditorialAttachment = ({ attachment, ...other }) => {
  switch (attachment.__typename) {
    case "LiferayAllegatoEditoriale":
      return <AllegatoEditoriale data={attachment} {...other} />;
    default:
      console.warn(
        `Cannot handle ${attachment.__typename} type for attachment content in pagina news`
      );
      return null;
  }
};

export default NewsEditorialAttachment;
export const query = graphql`
  fragment NewsEditorialAttachmentFragment on LiferayWebContent {
    __typename
    ...AllegatoEditorialeFragment
  }
`;
