import React from "react";
import ImageWrapper from "../elements/image-wrapper";
import SeeMore from "../elements/see-more";
import { Link } from "../link";

const NewsEditorialRelated = ({ editorialRelated }) => {
  return (
    <div className="correlato-editoriale">
      <h2 className="correlato-editoriale__title">Articoli correlati</h2>
      {editorialRelated.value.url.map((item, i) => (
        <Link className="correlato-editoriale__item" to={item?.value} key={i}>
          <ImageWrapper
            className="correlato-editoriale__img"
            image={item.content?.immagine?.node}
            alt=""
          />
          <div className="correlato-editoriale__content">
            {item.content?.titolo?.value && (
              <h3 className="correlato-editoriale__item-title clan-bold">
                {item.content.titolo.value}
              </h3>
            )}
            <SeeMore />
          </div>
        </Link>
      ))}
    </div>
  );
};

export default NewsEditorialRelated;
