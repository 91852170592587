import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import { Link } from "../link";
import SeeMore from "../elements/see-more";

const AllegatoEditoriale = ({ data }) => {
  const { url } = data;

  return (
    <div className="allegato-editoriale">
      <h2>Allegati</h2>
      {url?.map((attachment, i) => (
        <Link to={attachment?.value} key={i}>
          <h3
            dangerouslySetInnerHTML={{
              __html: attachment?.content?.titolo?.value || `Allegato ${i}`,
            }}
          ></h3>
          <SeeMore color="white" />
        </Link>
      ))}
    </div>
  );
};

export default withPreview(AllegatoEditoriale);
export const fragment = graphql`
  fragment AllegatoEditorialeFragment on LiferayAllegatoEditoriale {
    liferayFields {
      siteId
      articleId
    }
    url {
      value
      content {
        titolo {
          value
        }
      }
    }
  }
`;
